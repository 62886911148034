import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
// como cofiguro el cors en un servidor flask?
import { rootRouteRef } from './routes';
import { PodAnnotationApi, podAnnotationApiRef } from './api';

export const podAnnotationsPlugin = createPlugin({
  id: 'pod-annotations',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: podAnnotationApiRef,
      deps: { discoveryApi: configApiRef },
      factory: ({discoveryApi}) => {
        const baseUrl = discoveryApi.getOptionalString("backend.baseUrl")
        if (baseUrl === undefined) {
          throw new Error("backend.baseUrl is not defined")
        }
        return new PodAnnotationApi(baseUrl);
      },
    }),
  ],
});

export const PodAnnotationsPage = podAnnotationsPlugin.provide(
  createRoutableExtension({
    name: 'PodAnnotationsPage',
    component: () =>
      import('./components/mainComponent').then(m => m.PodAnnotationComponent),
    mountPoint: rootRouteRef,
  }),
);
