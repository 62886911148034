import React from 'react';
import { Chip, Tooltip, withStyles } from '@material-ui/core';

const P5Chip = withStyles({
  root: {
    backgroundColor: '#509cf2',
    color: '#1A1A1A',
    fontWeight: 'bold',
  }
})(Chip);
const P4Chip = withStyles({
  root: {
    backgroundColor: '#1cd95f',
    color: '#1A1A1A',
    fontWeight: 'bold',
  }
})(Chip);
const P3Chip = withStyles({
  root: {
    backgroundColor: '#f5b91d',
    color: '#1A1A1A',
    fontWeight: 'bold',
  }
})(Chip);
const P2Chip = withStyles({
  root: {
    backgroundColor: '#ef780a',
    color: '#1A1A1A',
    fontWeight: 'bold',
  }
})(Chip);
const P1Chip = withStyles({
  root: {
    backgroundColor: '#f42639',
    color: '#1A1A1A',
    fontWeight: 'bold',
  }
})(Chip);

const priorityLabels = {
  'P5': 'Informational',
  'P4': 'Low',
  'P3': 'Moderate',
  'P2': 'High',
  'P1': 'Critical',
} as Record<string, string>;

export const PriorityChip = ({ priority }: { priority: string }) => {
  let chip = <></>;
  switch (priority) {
    case 'P5':
      chip = <P5Chip label={priority.replace('P', '')} size='small'/>;
      break;
    case 'P4':
      chip = <P4Chip label={priority.replace('P', '')} size='small'/>;
      break;
    case 'P3':
      chip = <P3Chip label={priority.replace('P', '')} size='small'/>;
      break;
    case 'P2':
      chip = <P2Chip label={priority.replace('P', '')} size='small'/>;
      break;
    case 'P1':
      chip = <P1Chip label={priority.replace('P', '')} size='small'/>;
      break;
    default:
      chip = <Chip label={priority.replace('P', '')} size='small'/>;
  }

  return (
    <Tooltip title={priorityLabels[priority]}>
      <div>{chip}</div>
    </Tooltip>
  )
};
