import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  configApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { softwareCatalogApiRef, SoftwareCatalogApi } from './api';

export const softwareCatalogPlugin = createPlugin({
  id: 'software-catalog',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: softwareCatalogApiRef,
      deps: { discoveryApi: configApiRef },
      factory: ({ discoveryApi }) => {
        const url = discoveryApi.getString('backend.baseUrl');
        const token = discoveryApi.getOptionalString(
          'softwareCatalogPlugin.token',
        );
        return new SoftwareCatalogApi(url, token);
      },
    }),
  ],
});

export const SoftwareCatalogPage = softwareCatalogPlugin.provide(
  createRoutableExtension({
    name: 'SoftwareCatalogPage',
    component: () =>
      import('./components/SoftwareCatalog').then(
        m => m.SoftwareCatalogComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
