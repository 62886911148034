import React, { useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import {
  InfoCard,
  ResponseErrorPanel,
  Select,
} from '@backstage/core-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { QuantityInput } from './CustomNumberInput';
import { CreateAlertRequest, opsgenieApiRef } from '../../api';
import { ResponderRef } from '../../types';
// eslint-disable-next-line @backstage/no-forbidden-package-imports
import { getKhatuTkn } from 'app/src/cookieAuth';
import { CreateAlertFormProps, ORGANIZATIONAL_UNIT } from './types';
import { useNavigate } from 'react-router';

const DESCRIPTION_LENGTH_LIMIT = 80;
const MESSAGE_LENGTH_LIMIT = 200;
const OPSGENIE_SRE_ENGINEERING_TEAM_ID = '9c6327a7-1359-45e4-9d1c-4c387bd25678';

const KHATU_DEFAULT_TAGS = ['khatu', 'alert'];

const meet = 'https://meet.google.com/ygw-megh-vkv';

interface CreateAlertProps {
  description: string;
  message: string;
  priority: number;
  optionTeams: any;
  selectedTeams: string[];
}

export const CreateAlertForm: React.FC<{
  props: CreateAlertFormProps;
}> = ({ props }) => {
  const [values, setValues] = useState<CreateAlertProps>({
    description: '',
    message: '',
    priority: 1,
    optionTeams: props.teamOptions,
    selectedTeams: [],
  });
  const navigate = useNavigate();
  const opsgenieApi = useApi(opsgenieApiRef);
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [openInvalidFormDialog, setOpenInvalidFormDialog] =
    React.useState(false);

  const [errorMessage, setErrorMessage] = useState<Error | undefined>(
    undefined,
  );
  const handleTextChange = (key: keyof CreateAlertProps) => {
    return (event: { target: { value: any } }) => {
      setValues({ ...values, [key]: event.target.value });
    };
  };
  const sendAlert = async () => {
    try {
      setShowConfirmDialog(false);
      await opsgenieApi.createAlert(buildCreateAlertRequest(values));
      openOnCallMeet();
      navigate('/opsgenie/alerts');
    } catch (e: any) {
      setErrorMessage(e);
    }
  };

  const handleCreateAlertClick = async () => {
    if (isFormValid(values)) {
      setShowConfirmDialog(true);
    } else {
      setOpenInvalidFormDialog(true);
    }
  };

  if (errorMessage) return <ResponseErrorPanel error={errorMessage} />;

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        justifyContent="center"
        alignItems="center"
        xs={6}
        style={{ padding: 20 }}
        spacing={4}
      >
        <InfoCard title="Create Alert">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ padding: 20 }}
            spacing={4}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6}>
                <Select
                  multiple
                  items={values.optionTeams}
                  label="Team/s"
                  onChange={selectedTeams =>
                    (values.selectedTeams = selectedTeams as string[])
                  }
                />
              </Grid>
            </Grid>

            <Grid container item justifyContent="center" xs={12}>
              <Grid item xs={8}>
                <TextField
                  id="description"
                  label="Description"
                  fullWidth
                  variant="standard"
                  value={values.description}
                  onChange={handleTextChange('description')}
                  inputProps={{
                    maxLength: DESCRIPTION_LENGTH_LIMIT,
                  }}
                  helperText={`${values.description.length}/${DESCRIPTION_LENGTH_LIMIT}`}
                />
              </Grid>
              <Grid
                container
                item
                xs={4}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <div>Priority</div>
                <Grid
                  container
                  item
                  xs={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <QuantityInput
                    min={1}
                    max={5}
                    value={values.priority}
                    onChange={(_event, val = 1) =>
                      setValues({ ...values, priority: val })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container item justifyContent="center" xs={12}>
              <TextField
                id="message"
                label="Message"
                fullWidth
                margin="normal"
                variant="outlined"
                value={values.message}
                onChange={handleTextChange('message')}
                inputProps={{
                  maxLength: MESSAGE_LENGTH_LIMIT,
                }}
                helperText={`${values.message.length}/${MESSAGE_LENGTH_LIMIT}`}
              />
            </Grid>
            <Grid container item justifyContent="center" xs={12}>
              <Grid item xs={12}>
                <Alert severity="info">
                If the alert is directed to the "Cloudops_Team" team, please include in the message whether the problem occurred in "[financial]" or "[card]".
                  <p>
                  IMPORTANT: Guards can only be configured for productive environments.
                  </p>
                </Alert>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleCreateAlertClick}
              >
                Create Alert
              </Button>
            </Grid>
          </Grid>
        </InfoCard>
      </Grid>
      <Dialog
        open={showConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¡Atención, Ud está a punto de disparar una alerta!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Será redirigido al listado de alertas y a la reunión (Google Meet)
            para resolverla.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>Cancelar</Button>
          <Button onClick={() => sendAlert()}>Disparar Alerta</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openInvalidFormDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¡Todos los campos son requeridos!
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenInvalidFormDialog(false)}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

function buildTags(responders: ResponderRef[], message: string) {
  const tags = [...KHATU_DEFAULT_TAGS];

  if (responders.find(resp => resp.id === OPSGENIE_SRE_ENGINEERING_TEAM_ID)) {
    const lowerMessage = message.toLowerCase();
    if (lowerMessage.includes(ORGANIZATIONAL_UNIT.CARD)) {
      tags.push(ORGANIZATIONAL_UNIT.CARD);
    } else if (lowerMessage.includes(ORGANIZATIONAL_UNIT.FINANCIAL)) {
      tags.push(ORGANIZATIONAL_UNIT.FINANCIAL);
    }
  }
  return tags;
}

function openOnCallMeet() {
  window.open('https://meet.google.com/ygw-megh-vkv', '_blank', 'noreferrer');
}

function isFormValid(values: CreateAlertProps) {
  return (
    values.selectedTeams !== undefined &&
    values.selectedTeams.length > 0 &&
    values.message &&
    values.message.trim() !== '' &&
    values.description &&
    values.description.trim() !== ''
  );
}

function buildCreateAlertRequest(formData: CreateAlertProps) {
  const responders: ResponderRef[] = formData.selectedTeams.map(
    (aTeam: any) => ({
      id: aTeam,
      type: 'team',
    }),
  );
  const tags = buildTags(responders, formData.message);

  const description = `${formData.description}. User: ${getKhatuTkn()?.accessTkn.upn}. Meet: ${meet}` 

  const createAlertReq: CreateAlertRequest = {
    message: formData.message,
    description,
    priority: `P${formData.priority}`,
    responders: responders,
    visibleTo: responders,
    entity: getKhatuTkn()?.accessTkn.upn,
    tags: tags,
  };
  return createAlertReq;
}
