export class ApiError extends Error {
  constructor(message: string, private readonly status: number) {
    super(message);
    this.name = 'ApiError';
  }

  public get statusText(): string {
    return this.message;
  }

  public get statusNumber(): number {
    return this.status;
  }

  public static from(error: unknown): ApiError {
    if (error instanceof ApiError) {
      return error;
    }
    if (error instanceof Error) {
      return new ApiError(error.message, 500);
    }
    return new ApiError('Unknown error', 500);
  }

  public toJsonString(): string {
    return JSON.stringify({
      status: this.status,
      name: this.name,
      message: this.message,
    });
  }
}
